import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import PortableText from '../components/portableText'

import Layout from '../containers/layout'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import styles from '../styles/index.module.css'

export const query = graphql`
  # fragment SanityImage on SanityMainImage {
  #   crop {
  #     _key
  #     _type
  #     top
  #     bottom
  #     left
  #     right
  #   }
  #   hotspot {
  #     _key
  #     _type
  #     x
  #     y
  #     height
  #     width
  #   }
  #   asset {
  #     _id
  #   }
  # }
  fragment SanityTtlImage on SanityTitleImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  

  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      titleImage{
            ...SanityTtlImage
          }
      description
      keywords
    }
    about: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      title
      _rawDescription(resolveReferences: {maxDepth: 5})

    }
  }
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const about = (data || {}).about

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  if (!about) {
    throw new Error(
      'Missing "About". Open the studio at http://localhost:3333 and add some content to "About" and restart the development server.'
    )
  }
  return (
    <Layout>
    {
      site.titleImage && site.titleImage.asset && (
        <div className={styles.titleImage}>
          <img
          src={imageUrlFor(buildImageObj(site.titleImage))
            .width(1200)
            .fit('crop')
            .crop("right")
            .auto('format')
            .url()}
          />
        </div>
    )
          }
      <SEO
        title={about.title}
        description={about.description}
        keywords={site.keywords}
      />
      <Container>
        <h4>{about.title}</h4>
        <PortableText blocks={about._rawDescription} />
      </Container>
    </Layout>
  )
}

export default AboutPage
